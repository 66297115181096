import React from "react"
import calcDetails from "../calcrepo/calcDetails"
import CalcWidget from "../components/calcwidget"


export default function Products() {

  const calcElements = calcDetails.map(calc => {
    return <CalcWidget 
              calckey={calc.calckey} 
              calctitle={calc.calctitle} />
            })
  console.log (calcElements)
  return (
    <div>
      {calcElements}
    </div>
  )


  // return (
  //   <div className="content">
  //     <h3>Hoodies</h3>
  //     <div className="products">
  //       {calcList.map(p => (
  //         <div key={p}>
  //           <Link to={`/products/${p}`}>
  //             <img src="https://via.placeholder.com/250x150" alt="product" />
  //           </Link>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // )
}
