import React from "react";
import { useTranslation } from "react-i18next";
import { Route, useNavigate, Routes } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["about"]);

  return (
    <div className="content">
      <h2>{t("about:summary")}</h2>
      <p>{t("about:about")}</p>
      
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maxime tempora nisi eligendi quod ut illo cumque quibusdam reprehenderit veniam. Cum eaque, nostrum fugit ut inventore assumenda perspiciatis sed maiores numquam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati nam repellat hic dolore officia sequi quis quod commodi iusto doloremque. Corporis in et placeat unde sapiente perspiciatis minus! Recusandae, ad!</p>

      <button onClick={() => navigate('/products')}>See our products</button>

    </div>
  )
}
export default About;