export default [
    {
        calckey: "PercentageIncreace",
        calctitle: "Percentage Increace Calculator",
        calccategory: "Percentage",
        calclocation: "<<<location>>>",
        calchow: " Type in the numbers",
        calcwexplain: "This is how to culculate a percentage increase",
        calcexample: "Here is an example of a percentage increase"
    },
    {
        calckey: "PercentageChange",
        calctitle: "Percentage Change Calculator",
        calccategory: "Percentage",
        calclocation: "<<<location>>>",
        calchow: " Type in the numbers",
        calcwexplain: "This is how to culculate a percentage change",
        calcexample: "Here is an example of a percentage change"
    },
    {
        calckey: "Length",
        calctitle: "Length Calculator",
        calccategory: "Lengths",
        calclocation: "<<<location>>>",
        calchow: " Type in the numbers",
        calcwexplain: "This is how to convert cm to m",
        calcexample: "Here is an example of a cm to m"
    },
    {
        calckey: "Temp",
        calctitle: "Temperature Calculator",
        calccategory: "Temperature",
        calclocation: "<<<location>>>",
        calchow: " Type in the numbers",
        calcwexplain: "This is how to convert temperatures",
        calcexample: "Here is an example of a temperature conversion"
    }
]