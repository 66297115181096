import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// components
import Header from "./components/nav/Header";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import About from './pages/About';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';

const App = () => {
	return (
		<Suspense fallback={null}>
			<Router>
				<Header />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/profile" element={<Profile />} />
					<Route path="/about/*" element={<About />} />
					<Route path="/products/:id/*" element={<ProductDetails />} />
		            <Route path="/products" element={<Products />} />
				</Routes>
			</Router>
		</Suspense>
	);
};

export default App;
