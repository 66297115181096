import { Link } from "react-router-dom"
import React from "react";

export default function CalcWidget(props) {
    const urlPath = "/products/test";
    let urlPage = (props.calckey);
    let url = ((urlPath)+(urlPage));
    return (
        <div key={props.calckey}>
          <Link to={url}>
          < img src="https://via.placeholder.com/250x150" alt="product" />
          </Link>
          <p>{props.calckey}</p>
          <p>{props.calctitle}</p>
      </div>
)
}
